import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {

  let content = {
    Croatian: {
      main_title: "Zainteresiran? Saznaj više...!",
      card1_title: "Što je?",
      card1_text: "Što je Brazilian Jiu Jitsu?",
      card2_title: "Mreže",
      card2_text: "Prati nas na društvenim mrežama",
      card3_title: "Raspored",
      card3_text: "Raspored treninga: Početnici, Natjecatelji, Open Mat..",
      card4_title: "Informacije",
      card4_text: "Više o nama, koncept treninga...",
      card5_title: "Natjecanje",
      card5_text: "Natjecanja, pravila IBJJF, ADCC..",
    },
    English: {
      main_title: "Interested? Find out more...!",
      card1_title: "What is it?",
      card1_text: "What exactly is Brazilian Jiu Jitsu?",
      card2_title: "Network",
      card2_text: "Follow us on social media",
      card3_title: "Schedule",
      card3_text: "Training schedule: Beginners, Competitors, Open Mat..",
      card4_title: "Info",
      card4_text: "More about us, training concept...",
      card5_title: "Competition",
      card5_text: "Competitions, rules IBJJF, ADCC..",
    },
    German: {
      main_title: "Interessiert? Erfahren Sie mehr ...!",
      card1_title: "Was ist es?",
      card1_text: "Was ist der Brasilianische Jiu Jitsu?",
      card2_title: "Sozialnetzwerke",
      card2_text: "Folgen Sie uns über Sozialnetzwerke",
      card3_title: "Zeitplan",
      card3_text: "Trainingsplan: Anfänger, Wettbewerber, Open Mat ..",
      card4_title: "Infos",
      card4_text: "Mehr über uns, Trainingskonzept ...",
      card5_title: "Wettbewerbe",
      card5_text: "Wettbewerbe, Regeln IBJJF, ADCC...",
    }
  };

  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }

  return (
    <div className='cards'>
      <h1>{content.main_title}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/IMG_0424.jpg'
              text={content.card1_text}
              label={content.card1_title}
              path='/what-is-it'
            />
            <CardItem
              src='images/social_icons.jpg'
              text={content.card2_text}
              label={content.card2_title}
              path='/social-media'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/schedule.jpg'
              text={content.card3_text}
              label={content.card3_title}
              path='/schedule'
            />
            <CardItem
              src='images/aboutthumb.jpg'
              text={content.card4_text}
              label={content.card4_title}
              path='/about-us'
            />
            <CardItem
              src='images/img-8.jpg'
              text={content.card5_text}
              label={content.card5_title}
              path='/competitions'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
