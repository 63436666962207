import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {

  let content = {
    Croatian: {
      info_main: "Informacije",
      info_about: "O Nama",
      info_schedule: "Raspored",
      info_join: "Pridruži se",
      info_competition: "Natjecanja",
      info_follow: "Prati Nas",
    },
    English: {
      info_main: "Info",
      info_about: "About Us",
      info_schedule: "Schedule",
      info_join: "Join Us",
      info_competition: "Competition",
      info_follow: "Follow Us",

    },
    German: {
      info_main: "Infos",
      info_about: "Über uns",
      info_schedule: "Zeitplan",
      info_join: "Tritt bei",
      info_competition: "Wettbewerbe",
      info_follow: "Folge uns",

    }
  };
  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          "A boxer is like a lion, the greatest predator on land, but you throw him in the shark tank and he's just another meal."<br></br>  Renzo Gracie
        </p>
      </section>
      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>{content.info_main}</h2>
            <Link to='/about-us'>{content.info_about}</Link>
            <Link to='/schedule'>{content.info_schedule}</Link>
            <Link to='/join-us'>{content.info_join}</Link>
            <Link to='/competitions'>{content.info_competition}</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h2>{content.info_follow}</h2>
            <Link to='//www.instagram.com/ms.jiujitsu/'>Instagram</Link>
            <Link to='//www.facebook.com/MsBrazilianJiuJitsu/'>Facebook</Link>
            <Link to='//www.youtube.com/channel/UC2EFYaOspGbr7WBakX8yT3g'>Youtube</Link>
          </div>
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <Link to='/' className='social-logo'>
              <img className="logoimg" src="/images/cropped-ICON_LARGE-192x192.png" alt="MSJJK Logo" />

              MS Jiu-Jitsu
            </Link>
          </div>

          <div className="wrapper">
            <div className="inner1">
              <small className='website-rights'>MS JJK  © {(new Date().getFullYear())}</small>
            </div>
            <div className="inner1">
              <small className='website-rights'>Otokara Keršovanije 1, Čakovec</small>
            </div>
            <div className="inner1">
              <small className='website-rights'>Website by: <a href="https://www.linkedin.com/in/gorandavid/"> Goran David</a></small>
            </div>
            <div className="inner1">
              <small className='website-rights'>Photo: <a href="https://www.instagram.com/darkmares/"> Darko Mareš</a> / <a href="https://www.instagram.com/tin_photoo/"> Tin Tomanić</a></small>
            </div>
          </div>


          <div className='social-icons'>
            <Link
              className='social-icon-link facebook'
              to='//www.facebook.com/MsBrazilianJiuJitsu/'
              target='_blank'
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </Link>
            <Link
              className='social-icon-link instagram'
              to='//www.instagram.com/ms.jiujitsu/'
              target='_blank'
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </Link>
            <Link
              className='social-icon-link youtube'
              to='//www.youtube.com/channel/UC2EFYaOspGbr7WBakX8yT3g'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
