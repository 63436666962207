import React, { useEffect } from 'react';
import Footer from './Footer';

function NotFound() {

  // used to scroll smoothly on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  })


  let languageStoredInLocalStorage = localStorage.getItem("language");

  let content = {
    Croatian: {
      buttonText:"Početna stranica",
      notfoundTitle: "Stranica nije pronađena",
      notfoundText: "Vjerojatno si ovdje završio slučajno, vrati se na početak i pokušaj ponovo!",

    },
    English: {
      buttonText:"Home Page",
      notfoundTitle: "Page not found.",
      notfoundText: "You probably ended up here by accident, go back to the beginning and try again!",
    },
    German: {
      buttonText:"Startseite",
      notfoundTitle: "Seite nicht gefunden.",
      notfoundText: "Sie sind wahrscheinlich aus Versehen hier gelandet. Gehen Sie zurück zum Anfang und versuchen Sie es erneut!",    }
  };

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }


  return (
    <>
      <section className="wrapper style1">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col3">
              <div className="image round fit">
                <img src="images\p404.jpg" alt="" />
              </div>
            </div>
            <div className="col col4">
              <h3 className="joinUsH3">{content.notfoundTitle}</h3>
              <br />
              <p className="joinUsMiddleText">{content.notfoundText}</p><br></br>
              <a href="/" className="button3">{content.buttonText}</a>
              <br />
              <ul>
                <br />
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>

    </>
  );
}

export default NotFound;
