import React, { useState, useEffect } from 'react';
import { JoinUsButton } from './JoinUsButton';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar(props) {

  let languageStoredInLocalStorage = localStorage.getItem("language");
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);


  let content = {
    Croatian: {
      home: "Početna",
      gallery: "Galerija",
      about: "O Nama",
      join: "Pridruži Se"
    },
    English: {
      home: "Home",
      gallery: "Gallery",
      about: "About Us",
      join: "Join Us"
    },
    German: {
      home: "Startseite",
      gallery: "Galerie",
      about: "Über uns",
      join: "Tritt bei"
    }
  };

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }


  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img className="logoimg" src="/images/cropped-ICON_LARGE-192x192.png" alt="MSJJK Logo" />
            MS Jiu-Jitsu
            <svg x="0px" y="0px" viewBox="0 0 498 53.9">
              <path d="M297.5,41.2h-76.6c-0.5,0-0.9,0.4-1,0.8l-1.6,11.3l-3.1-32c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-1,0.8
                       l-5.3,25.5l-2.3-10.9c-0.1-0.4-0.4-0.7-0.9-0.8c-0.4,0-0.8,0.2-1,0.6l-2.3,4.8h-107c0,0,0,0,0,0H82c-1.6,0-2.2,1.1-2.2,1.6
                       l-1.6,11.3l-3.1-52c0-0.5-0.4-0.9-0.9-0.9c-0.5,0-0.9,0.3-1,0.8l-9.3,45.5l-2.3-10.9c-0.1-0.4-0.4-0.7-0.9-0.8c-0.4,0-0.8,0.2-1,0.6
  l-2.3,4.8H0.5"/>¸
</svg>
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                {content.home}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/gallery'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {content.gallery}
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/about-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                {content.about}
              </Link>
            </li>

            <li >  <div className='nav-item-div'>
              <select
                className='nav-select'
                value={props.language}
                onChange={e => props.handleSetLanguage(e.target.value)}
                >
                <option value="Croatian" disabled >Language</option>
                <option value="Croatian">Croatian</option>
                <option value="English">English</option>
                <option value="German">German</option>
              </select>
            </div>
            </li>
            <li>
              <Link
                to='/join-us'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                {content.join}
              </Link>
            </li>
          </ul>
          {button && <JoinUsButton buttonStyle='btn--outline'>              {content.join}</JoinUsButton>}

        </div>
      </nav>
    </>
  );
}

export default Navbar;
