import React, { useEffect } from 'react';
import '../../App.css';
import JoinUsTop from './joinUsTop';
import Footer from '../Footer';

export default function JoinUs() {

  // used to scroll smoothly on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  })

  return (

    <div>
      <JoinUsTop />
      <Footer />
    </div>
  );
}
