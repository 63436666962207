import React, { useEffect } from 'react';
import '../../App.css';
import GalleryContainer from './GalleryContainer';


export default function MSGallery() {
    // used to scroll smoothly on page load
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    })
  
  return (
    <div>

      <GalleryContainer />
    </div>

  );
}
