import React, { useEffect } from 'react';
import '../../App.css';
import './AboutUs.css';
import Footer from '../Footer';

export default function AboutUs() {
	// used to scroll smoothly on page load
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	})

	let content = {
		Croatian: {
			titleSub: "Brazilian Jiu Jitsu klub koji djeluje od 2013. godine.",
			maintext_p1: "MS Jiu Jitsu klub ima dugogodišnju tradiciju. Osnovao ga je, sad već davne 2013. godine, glavni trener Luka Mošmondor kao dodatak spektru treninga borilačkih sportova Muraken Gyma u Murskom Središću. Time se MS Jiu Jitsu klub približio Brazilian Jiu Jitsu i Grappling regiji sjeverozapadne Hrvatske pa i šire. U godinama djelovanja klub je primio brojne nove članove. MSJJK se može pohvaliti brojnim gostovanjima u drugim klubovima te natjecanjima diljem regije pa i svijeta. Članovi se mogu pohvaliti vrijednim medaljama iz natjecanja te gostovanjima u Hrvatskoj, Sloveniji, Mađarskoj, Bosni i Hercegovini, Srbiji, Austriji, Portugalu, Poljskoj i čak na udaljenim lokacijama kao što je Kenija - što je samo dodatna potvrda kako je Brazilian Jiu Jitsu postao globalni fenomen u posljednjih nekoliko desetljeća.",
			maintext_p2: "U godinama djelovanja MSJJK je i sam održavao seminare u svom klubu, što u svojim prostorima, što na gostovanjima te u suradnji sa sestrinskim klubovima. U tom tonu 2020. godine po prvi je puta održan MS Jiu Jitsu Summer Camp koji je imao odličan odaziv zainteresiranih iz Zagreba, Varaždina, Austrije i Poljske čiji su polaznici u četiri dana odradili brojne treninge, ali i neobavezna druženja u prekrasnim predjelima okolice kluba.",
			maintext_p3: "Treneri, kao i sami članovi kluba uvijek teže dodatnoj edukaciji i uvijek su otvoreni prema posjetama iz drugih klubova pa tako objeručke primaju zainteresirane - i samo na friendly sparring (Judo, Wrestling, Ju-Jutsu te ostale borilačke vještine uvijek su dobrodošle u svrhu razmjene znanja). Također, naši članovi konstantno rade na svojoj osobnoj edukaciji šireći znanje na raznim seminarima i edukacijama.",
			coachesTitle: "Naši treneri",
			coachesSubTitle: "Majstori svog zanata, predani svakom treningu",
			coach1: "Osnivač kluba i glavni trener. Crni pojas. Sve što danas postoji u vezi s klubom ostvareno je njegovim entuzijazmom.",
			coach2: "Jedan od prvih u regiji koji se upoznao s Brazilian Jiu Jitsuom te je prisutan i dalje na svim treninzima. Crni pojas.",
			coach3: "Jedna od najentuzijastičnijih osoba BJJ-a, ne propušta niti jedno natjecanje ili trening. Ljubičasti pojas i trenutni predsjednik kluba.",
			trainConcept: "Koncept treninga",
			trainConceptSub: "Za one koji nisu upoznati s MS Jiu Jitsu klubom, a imaju ambicije pridružiti se, koncept regularnog treninga je sačinjen od nekoliko djelova:",
			warmup: "Zagrijavanje",
			warmupSub: "10-20 minuta zagrijavanja za snagu i kondiciju. Zagrijavanje je koncipirano u stilu HIIT (High Intensity Interval Training) treninga s naglaskom na vježbe koje su slične potezima korištenima u Jiu Jitsuu.",
			technique: "Tehnike",
			techniqueSub: "20-40 minuta učenja i ponavljanja određene tehnike. Tijekom tjedna uče se tehnike koje nadopunjuju jedna drugu: tehnike rušenja, napada i obrane.",
			sparring: "Sparing",
			sparringSub: "20-40 minuta sparinga (rolanja) s trening partnerima u kojem se pokušaju primijeniti naučene tehnike pri čemu partner daje otpor. Sparing se uobičajeno odvija u rundama od 5 minuta, uz 90 sekundi pauze između rundi.",
			strech: "Istezanje",
			strechSub: "5-10 minuta. Lagano hlađenje, smirivanje disanja i otkucaja srca, istezanje mišića u svrhu sprječavanja upale i ozljeda",
			addText: "Ukupni trening uobičajeno traje 90 minuta, iako ovo vrijeme može varirati, posebice za open-mat treninge (sparing trening). Početničke grupe traju 60 minuta, a u trening nije uključeno spariranje. ",
			etiquette: "Brazilian Jiu Jitsu Bonton",
			etiquetteSub: "Prije prvog treninga molimo da proučite osnovni bonton BJJ-a, a svemu ostalome podučit će vas ekipa koja je uvijek voljna pomoći. BJJ bonton će osigurati da svatko ima siguran, zabavan i koristan trening u okruženju koje je pogodno za učenje.",
			basicRules: "Osnovna pravila BJJ treninga:",
			etiquetteRule1: "1. Budi točan. 10-15 minuta prije treninga budi odjeven i u dvorani. Ako kasniš ili odlaziš ranije pitaj za dopuštenje.",
			etiquetteRule2: "2. Budi svjestan svoje osobne higijene i dolazi u čistoj odjeći. Nokti kratko odrezani, kosa svezana, nakit i piercinge prije treninga ukloni. U slučaju prehlade, viroze ili infekcije kože neće ti biti dozvoljeno trenirati.",
			etiquetteRule3: "3. Obuća, hrana i piće nisu dozvoljeni na strunjači. Odlazak na WC obavezan u obući. Molimo pomoć kod čišćenja dvorane nakon treninga.",
			etiquetteRule4: "4. Sigurnost je najvažnija. Budi svjestan svojih granica i granica tvojih trening partnera. Svaki zahvat uvijek primijeni kontrolirano. Tapkanje (predaja) nije znak slabosti, već je dio procesa učenja.",
			etiquetteRule5: "5. Ostavi svoj ego na vratima. Trening je učenje, a ne natjecanje.",
			etiquetteRule6: "6. Kratki naklon je uobičajen prilikom ulaska i izlaska iz dvorane te je način izraza poštovanja prema ljudima i mjestu gdje se trenira.",
			etiquetteRule7: "7. Poštuj svoje instruktore i kolege. Izbjegavaj glasno govoriti, psovati ili provocirati tijekom treninga. Prije početka stani u liniju i nakloni se instruktoru.",
			etiquetteRule8: "8. Iskoristi svoje vrijeme na treningu. Radi zajedno sa svojim partnerima na obostranu korist. Izbjegavaj davanje pretjeranog otpora tvojem partneru kod vježbanja tehnika. Ako ne razumiješ tehniku, postavljaj pitanja.",
			etiquetteRule9: "9. Pridržavaj se pravila BJJ-a tijekom treninga. Ne koristi ilegalne tehnike radi vlastite sigurnosti i sigurnosti tvojih trening partnera.",
			etiquetteRule10: "10. Nemoj eksperimentirati savladanim tehnikama niti ih pokazivati na drugima, osim u slučaju samoobrane.",
			illegalTehniques: "ILEGALNE TEHNIKE:",
			illegalTehniquesText: "UDARANJE RUKAMA ILI NOGAMA, GURANJE PRSTIJU U OČI, USTA, NOS, POTEZANJE ZA KOSU ILI ODJEĆU (NO-GI), HVATANJE UNUTAR RUKAVA ILI NOGAVICA (GI)!, POLUGE I MANIPULACIJE NA VRATU, KRALJEŽNICI, PRSTIMA I NOGAMA (KOLJENO, GLEŽANJ)!, PODIZANJE S PODA I BACANJE (SLAM), SUPLEX I BACANJE NA GLAVU ! U slučaju ne pridržavanja gore navedenih pravila bit ćete zamoljeni da napustite trening!",

		},
		English: {
			titleSub: "Brazillian Jiu Jitsu club that has been operating since 2013.",
			maintext_p1: "MS Jiu Jitsu Club today already has a long tradition, founded back in 2013 by head coach Luka Mošmondor as an addition to the range of Muraken Gym martial arts trainings in Mursko Središće. With this, the MS Jiu Jitsu Club brought Brazilian Jiu Jitsu and the Grappling closer to northwestern region of Croatia and beyond. In the years of operation, the club has received many new members. MSJJK boasts numerous appearances in other clubs and competitions across the region and the world. Its members can boast valuable medals from the competition and guest appearances in Croatia, Slovenia, Hungary, Bosnia and Herzegovina, Serbia, Austria, Portugal, Poland, and even remote locations such as Kenya, which is just further confirmation of how Brazilian Jiu Jitsu became a global phenomenon in recent decades.",
			maintext_p2: "During the years of its operation, the MSJJK itself has held seminars in its club, both in its premises, in guest appearances, and in cooperation with sister clubs. In that tone, in 2020, the MS Jiu Jitsu Summer Camp was held for the first time, which had an excellent response from those interested from Zagreb, Varaždin, Austria and Poland. Whose participants did numerous trainings in four days, but also the non-obligatory socializing in the beautiful areas around the club. ",
			maintext_p3: "Coaches, as well as the club members themselves, always strive for additional education, and are always open to visits from other clubs, so they reluctantly receive those interested, even if only for friendly sparring (Judo, Wrestling, Ju-Jutsu and other martial arts are always welcome in purpose of knowledge exchange). Also, our members are constantly working on their personal education by spreading knowledge at various seminars and trainings.",
			coachesTitle: "Our coaches",
			coachesSubTitle: "Masters of their craft, committed to every training",
			coach1: "Founder of the club and head coach. Black belt. Everything that exists today about the club has been achieved with his enthusiasm.",
			coach2: "One of the first in the region to train Brazilian Jiu Jitsu and is still present in all training sessions. Black belt.",
			coach3: "One of the most enthusiastic people of BJJ, he does not miss a single competition or training. Purple belt and the current president of the club.",
			trainConcept: "Training Concept",
			trainConceptSub: "For those unfamiliar with MS Jiu Jitsu Club, and have ambitions to join, the concept of regular training is made up of several parts:",
			warmup: "Warming up",
			warmupSub: "10-20 minutes of warm-up for strength and fitness. The warm-up is designed in the style of HIIT (High Intensity Interval Training) training with an emphasis on exercises similar to the moves used in Jiu Jitsu.",
			technique: "Techniques",
			techniqueSub: "20-40 minutes of learning and repeating a certain technique. During the week, techniques that complement each other are learned; take-downs, attack and defense techniques.",
			sparring: "Sparring",
			sparringSub: "20-40 minutes of sparring (rolling) with training partners in which they try to apply the learned techniques. Sparring usually takes place in rounds of 5 minutes, with a 90 second break between rounds.",
			strech: "Stretching",
			strechSub: "5-10 minutes. Slight cooling and calming of breathing and heartbeat. And stretching of muscles in order to prevent inflammation and injuries",
			addText: "Total duration of one training is usually 90 minutes, although this may vary, especially for Open-Mat training (sparring training). Beginner groups last 60 minutes and no sparring is included in the training.",
			etiquette: "Brazilian Jiu Jitsu Etiquette",
			etiquetteSub: "Before the first training, please study the basic etiquette of BJJ, and everything else will be taught to you by a team that is always willing to help. BJJ etiquette will ensure that everyone has a safe, fun and rewarding workout in a learning environment.",
			basicRules: "Basic rules of BJJ training:",
			etiquetteRule1: "1. Be accurate. 10-15 minutes before training, be dressed in the gym. If you are late or leave early ask for permission.",
			etiquetteRule2: "2. Be aware of your personal hygiene and come in clean clothes. Nails cut short, hair tied, jewelry and piercings removed before training. In case of a cold, virus or skin infection, you will not be allowed to train.",
			etiquetteRule3: "3. Footwear, food and drink are not allowed on the mat. Going to the toilet is mandatory in shoes. Please help with cleaning the gym after training",
			etiquetteRule4: "4. Safety is paramount. Be aware of your limits and the limits of your training partners. Always apply each procedure in a controlled manner. Tapping (surrender) is not a sign of weakness but is part of the learning process.",
			etiquetteRule5: "5. Leave your ego at the door. Training is learning, not competition.",
			etiquetteRule6: "6. A short bow is common when entering and leaving the gym and is a way of expressing respect for people and the place where you train.",
			etiquetteRule7: "7. Respect your instructors and colleagues. Avoid talking loudly, swearing or provoking during training. Before starting, stand in line and bow to the instructor.",
			etiquetteRule8: "8. Use your training time. Work together with your partners for mutual benefit. Avoid giving excessive resistance to your partner when practicing techniques. If you don’t understand the technique, feel free to ask questions.",
			etiquetteRule9: "9. Adhere to BJJ rules during training. Do not use illegal techniques for your own safety and the safety of your training partners.",
			etiquetteRule10: "10. Do not experiment or show mastered techniques to others, except in the case of self-defense.",
			illegalTehniques: "ILLEGAL TECHNIQUES:",
			illegalTehniquesText: "HITTING HANDS OR FEET, PUSHING FINGERS INTO EYES, MOUTH, NOSE, PULLING HAIR OR CLOTHES (NO-GI) CATCHING INSIDE SLEEVES OR SOCKS (GI)! LEVERS AND MANIPULATIONS ON THE NECK, SPINE, FINGERS AND LEGS (KNEE, ANKLE)! LIFTING FROM THE FLOOR THROWING (SLAM), SUPLEX AND THROWING ON THE HEAD! In case of non-compliance with the above rules you will be asked to leave the training!",
		},
		German: {
			titleSub: "Brasilianischer Jiu Jitsu Klub, der seit 2013 aktiv ist.",
			maintext_p1: "Der MS Jiu Jitsu Klub hat bereits eine lange Tradition und wurde 2013 vom Cheftrainer Luka Mošmondor als Ergänzung zum Angebot an Kampfsporttrainings im Muraken Gym in Mursko Središće gegründet. Damit brachte der MS Jiu Jitsu Klub das brasilianische Jiu Jitsu und das Grappling näher an die nordwestliche Region Kroatiens und darüber hinaus. In den Betriebsjahren hat der Klub viele neue Mitglieder aufgenommen. MSJJK bietet zahlreiche Auftritte in anderen Vereinen und Wettbewerben in der Region und der Welt. Seine Mitglieder können sich mit wertvollen Medaillen aus dem Wettbewerb und Gastauftritten in Kroatien, Slowenien, Ungarn, Bosnien und Herzegowina, Serbien, Österreich, Portugal, Polen und sogar an abgelegenen Orten wie Kenia rühmen. Dies ist nur eine weitere Bestätigung dafür, wie das brasilianische Jiu Jitsu ein globales Phänomen in den letzten Jahrzehnten geworden ist. ",
			maintext_p2: "In den Jahren seiner Tätigkeit hat der MSJJK selbst in seinem Klub Seminare abgehalten - sowohl in seinen Räumlichkeiten als auch bei Gastauftritten und in Zusammenarbeit mit Schwesterklubs. In diesem Ton fand 2020 zum ersten Mal das MS Jiu Jitsu Sommercamp statt, das von Interessenten aus Zagreb, Varaždin, Österreich und Polen sehr gut angenommen wurde. Die Teilnehmer haben während vier Tagen zahlreiche Schulungen durchgeführt, aber auch die unverbindlichen Treffen in den schönen Gegenden rund um den Klub.",
			maintext_p3: "Sowohl die Trainer als auch die Klubmitglieder selbst bemühen sich stets um zusätzliche Fortbildung und sind immer offen für Besuche anderer Klubs. Daher erhalten sie gern Interessierte, auch wenn dies nur für freundschaftliches Sparring gilt - Judo, Wrestling, Ju-Jutsu und andere Kampfsportarten sind zum Zweck des Wissensaustauschs immer willkommen. Außerdem arbeiten unsere Mitglieder ständig an ihrer persönlichen Bildung, indem sie an verschiedenen Seminaren und Schulungen ihr Wissen verbreiten. ",
			coachesTitle: "Unsere Trainer",
			coachesSubTitle: "Meister ihres Faches, die sich jedem Training völlig widmen",
			coach1: "Gründer des Vereins und Cheftrainer. Schwarzer Gürtel. Alles, was heute über den Verein existiert, wurde mit seiner Begeisterung erreicht. ",
			coach2: "Einer der ersten in der Region, der das brasilianische Jiu Jitsu trainiert hat und immer noch bei jedem Training anwesend ist. Schwarzer Gürtel. ",
			coach3: "Als einer der enthusiastischsten Menschen von BJJ verpasst er keinen einzigen Wettkampf oder Training. Lila Gürtel und der derzeitige Präsident des Klubs. ",
			trainConcept: "Trainingskonzept ",
			trainConceptSub: "Für diejenigen, die mit dem MS Jiu Jitsu Klub nicht vertraut sind und Ambitionen haben, Mitglied zu werden, besteht das Konzept des regelmäßigen Trainings aus mehreren Teilen: ",
			warmup: "Aufwärmen ",
			warmupSub: "10-20 Minuten Aufwärmen für Kraft und Form. Das Aufwärmen ist im Stil des HIIT-Trainings (High Intensity Interval Training) konzipiert, wobei der Schwerpunkt auf Übungen liegt, die den in Jiu Jitsu verwendeten Bewegungen ähneln. ",
			technique: "Techniken ",
			techniqueSub: "20-40 Minuten lernen und eine bestimmte Technik wiederholen. Während der Woche werden Techniken erlernt, die sich gegenseitig ergänzen. Niederlagen, Angriffs- und Verteidigungstechniken.",
			sparring: "Sparring",
			sparringSub: "20-40 Minuten Sparring (Rollen) mit Trainingspartnern, in denen sie versuchen, die erlernten Techniken anzuwenden. Sparring findet normalerweise in Runden von 5 Minuten statt, mit einer Pause von 90 Sekunden zwischen den Runden.",
			strech: "Dehnen",
			strechSub: "5-10 Minuten. Leichte Abkühlung und Beruhigung von Atmung und Herzschlag. Dehnung der Muskeln, um Entzündungen und Verletzungen vorzubeugen ",
			addText: "Die Gesamtdauer eines Trainings beträgt normalerweise 90 Minuten, obwohl dies insbesondere beim Open-Mat-Training (Sparring-Training) variieren kann. Anfängergruppen dauern 60 Minuten und es ist kein Sparring im Training enthalten. ",
			etiquette: "Brasilianische Jiu Jitsu Etikette",
			etiquetteSub: "Bitte lernen Sie vor dem ersten Training die grundlegende Etikette von BJJ kennen. Alles andere wird Ihnen vom Team beigebracht, das immer bereit ist, Ihnen zu helfen. Die BJJ-Etikette stellt sicher, dass jeder ein sicheres, unterhaltsames und lohnendes Training in einer Lernumgebung hat.",
			basicRules: "Grundregeln des BJJ-Trainings:",
			etiquetteRule1: "1. Seien Sie genau. Ziehen Sie sich 10-15 Minuten vor dem Training im Fitnessstudio an. Wenn Sie zu spät kommen oder früher gehen müssen, bitten Sie um Erlaubnis.",
			etiquetteRule2: "2. Achten Sie auf Ihre persönliche Hygiene und tragen Sie saubere Kleidung. Nägel kurz geschnitten, Haare gebunden, Schmuck und Piercings vor dem Training entfernt. Im Falle einer Erkältung, eines Virus oder einer Hautinfektion dürfen Sie nicht trainieren.",
			etiquetteRule3: "3. Schuhe, Essen und Trinken sind auf der Matte nicht erlaubt. In Schuhen ist es obligatorisch, auf die Toilette zu gehen. Bitte helfen Sie beim Reinigen des Fitnessraums nach dem Training ",
			etiquetteRule4: "4. Sicherheit ist oberstes Gebot. Seien Sie sich Ihrer Grenzen und der Grenzen Ihrer Trainingspartner bewusst. Wenden Sie jedes Verfahren immer kontrolliert an. Klopfen (Übergeben) ist kein Zeichen von Schwäche, sondern Teil des Lernprozesses.",
			etiquetteRule5: "5. Lass dein Ego an der Tür. Training ist Lernen, kein Wettbewerb.",
			etiquetteRule6: "6. Ein kurzer Bogen ist beim Betreten und Verlassen des Fitnessraums üblich und ist ein Ausdruck des Respekts für die Menschen und den Ort, an dem Sie trainieren.",
			etiquetteRule7: "7. Respektieren Sie Ihre Trainer und Kollegen. Vermeiden Sie es, während des Trainings laut zu sprechen, zu fluchen oder zu provozieren. Stellen Sie sich vor dem Start in die Schlange und verneigen Sie sich vor dem Trainer.",
			etiquetteRule8: "8. Nutzen Sie Ihre Trainingszeit gut aus. Arbeiten Sie zum gegenseitigen Nutzen mit Ihren Partnern zusammen. Vermeiden Sie es, Ihrem Partner beim Üben von Techniken übermäßigen Widerstand zu leisten. Wenn Sie die Technik nicht verstehen, können Sie gerne Fragen stellen.",
			etiquetteRule9: "9. Beachten Sie während des Trainings die BJJ-Regeln. Verwenden Sie keine illegalen Techniken zu Ihrer eigenen Sicherheit und zur Sicherheit Ihrer Trainingspartner.",
			etiquetteRule10: "10. Experimentieren Sie nicht und zeigen Sie anderen keine beherrschten Techniken, außer im Fall der Selbstverteidigung.",
			illegalTehniques: "ILLEGALE TECHNIKEN:",
			illegalTehniquesText: "HÄNDE ODER FÜSSE SCHLAGEN, FINGER IN AUGEN, MUND, NASE, HAAR ODER KLEIDUNG (NO-GI) DRÜCKEN, ÄRMEL ODER SOCKEN (GI) FANGEN!, Hebel und Manipulationen an Hals, Wirbelsäule, Fingern und Beinen (Knie, Knöchel)!, vom Boden heben und werfen (zuschlagen), SUPLEX und auf den Kopf werfen! Bei Nichteinhaltung der oben genannten Regeln werden Sie gebeten, das Training zu verlassen!",
		}
	};
	
	let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

	switch (languageStoredInLocalStorage) {
		case 'English':
			content = content.English;
			break;
		case 'German':
			content = content.German;
			break;
		default:
			content = content.Croatian;
	}

	return (
		<div className="wrapper style1 topreg">

			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h2 className="msabouttitle">MS Jiu Jitsu Club</h2>
						<h3>{content.titleSub}</h3>
					</header>
					<div className="flex flex-2">
						<div className="col cola2">
							<p className="msmaintext justifyptext">{content.maintext_p1}</p>
							<p className="msmaintext justifyptext"><br></br> {content.maintext_p2}</p>
						</div>
						<div className="col cola1 first">
							<div className="image round3 fit">
								<img src="images/33.jpg" alt="" />
							</div>
						</div>
					</div><br></br>
					<p className="msmaintext justifyptext">{content.maintext_p3}</p>
				</div>
			</section>

			<hr className="major" />


			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h1 className="msabouttitle">{content.coachesTitle}</h1>
						<p>{content.coachesSubTitle}</p>
					</header>
					<div className="flex flex-3">
						<div className="col align-center">
							<div className="image round fit2">
								<img src="images/trainer_luka.jpg" alt="Luka" />
							</div>
							<h3 className="addi">Luka Mošmondor </h3>
							<p>{content.coach1}</p>
						</div>
						<div className="col align-center">
							<div className="image round fit2">
								<img src="images/trainer_tomislav.jpg" alt="Tomislav" />
							</div>
							<h3 className="addi">Tomislav Sobočan </h3>
							<p>{content.coach2}</p>
						</div>
						<div className="col align-center">
							<div className="image round fit2">
								<img src="images/trainer_alen.jpg" alt="Alen" />
							</div>
							<h3 className="addi">Alen Kontrec </h3>
							<p>{content.coach3}</p>
						</div>
					</div>
				</div>
			</section>
			<hr></hr>

			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h2 className="msabouttitle">{content.trainConcept}</h2>
						<h3>{content.trainConceptSub}</h3>
					</header>
					<div className="flex flex-2">
						<div className="col cola2">
							<ul>
								<li className="msmaintext">{content.warmup}</li>
								<p>{content.warmupSub}</p>
								<br />
								<li className="msmaintext">{content.technique}</li>
								<p>{content.techniqueSub}</p>
								<br />
								<li className="msmaintext">{content.sparring}</li>
								<p>{content.sparringSub}</p>
								<br />
								<li className="msmaintext">{content.strech}</li>
								<p>{content.strechSub}</p>
								<br />
							</ul>  						</div>
						<div className="col cola1 first">
							<div className="image round3 fit">
								<img src="images/concept_guard_al.jpg" alt="" />
							</div>
						</div>
					</div><br></br>
					<p className="msmaintext">{content.addText}</p>
					<br></br>
				</div>
			</section>
			<hr></hr>
			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h2 className="msabouttitle">{content.etiquette}</h2>
						<h3>{content.etiquetteSub}</h3>
					</header>
					<p className="msmaintext justifyptext">{content.basicRules}<br /><br />
						{content.etiquetteRule1}<br /><br />
						{content.etiquetteRule2}<br /><br />
						{content.etiquetteRule3}<br /><br />
						{content.etiquetteRule4}<br /><br />
						{content.etiquetteRule5}<br /><br />
						{content.etiquetteRule6}<br /><br />
						{content.etiquetteRule7}<br /><br />
						{content.etiquetteRule8}<br /><br />
						{content.etiquetteRule9}<br /><br />
						{content.etiquetteRule10}<br /><br /></p>
					<p className="msmaintext justifyptext"><br />{content.illegalTehniques}<br />
						{content.illegalTehniquesText}<br /><br /><br /></p>
				</div>
			</section>
			<Footer />

		</div>

	);
}
