import React, { useEffect } from 'react';
import '../../App.css';
import './Schedule.css';
import Footer from '../Footer';

export default function Schedule() {

  // used to scroll smoothly on page load
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  })

  let content = {
    Croatian: {
      monday: "Ponedjeljak",
      tuesday: "Utorak",
      wednesday: "Srijeda",
      thursday: "Četvrtak",
      friday: "Petak",
      saturday: "Subota",
      sunday: "Nedjelja",
      advanceGroup: "(Napredna grupa)",
      beginnerGroup: "(Početnička grupa)",
      scheduleTopic: "Tjedni raspored treninga",
      scheduleText: "MS Jiu Jitsu klub na svojim treninzima srdačno prima članove drugih klubova iz bilo koje države. Uz navedene treninge iz donje tablice postoji mogućnost dodatnih treninga po dogovoru članova (ili otkazivanja u slučaju više sile). Za moguće promjene molimo pratite neku od društvenih mreža.",
      scheduleBullet1: "NoGi: Bez kimona (Gi-a), dobrodošle sve grupe",
      scheduleBullet2: "Napredna grupa: Plavi pojasi i više, dobrodošli i početnici",
      scheduleBullet3: "Početnička grupa: Početničke tehnike, bez spariranja",
      scheduleBullet4: "Open Mat: Sparing trening, dobrodošle sve grupacije i gosti, Gi/NoGi, MMA",
      restday: "Dan Odmora",
      strahoninec: "Strahoninec",
      murskosredisce: "Mursko Središće",
      cakovec: "Čakovec",


    },
    English: {
      monday: "Monday",
      tuesday: "Tuesday",
      wednesday: "Wednesday",
      thursday: "Thursday",
      friday: "Friday",
      saturday: "Saturday",
      sunday: "Sunday",
      advanceGroup: "(Advanced Group)",
      beginnerGroup: "(Beginner Group)",
      scheduleTopic: "Weekly training schedule",
      scheduleText: "MS Jiu Jitsu Club, on its trainings, warmly welcomes members of other clubs from any country. In addition to the trainings from the table below, there is possibility of additional trainings by agreement of members (or cancellation in case of higher force). For possible changes, please follow one of the social networks. ",
      scheduleBullet1: "NoGi: No kimono (Gi), all groups",
      scheduleBullet2: "Advanced Group: Blue Belts and upper, beginners are welcome",
      scheduleBullet3: "Beginner group: Beginner techniques, no sparring",
      scheduleBullet4: "Open Mat: Sparring training, welcome all groups and guests, Gi/NoGi, MMA",
      restday: "Rest Day",
      strahoninec: "Strahoninec",
      murskosredisce: "Mursko Središće",
      cakovec: "Čakovec",

    },
    German: {
      monday: "Montag",
      tuesday: "Dienstag",
      wednesday: "Mittwoch",
      thursday: "Donnerstag",
      friday: "Freitag",
      saturday: "Samstag",
      sunday: "Sonntag",
      advanceGroup: "(Fortgeschrittene Gruppe)",
      beginnerGroup: "(Anfängergruppe)",
      scheduleTopic: "Wöchentlicher Trainingsplan",
      scheduleText: "MS Jiu Jitsu Klub begrüßt auf seinem Training die Mitglieder anderer Klubs aus jedem Land. Neben den Trainings aus der Tabelle unten gibt es die Möglichkeit, zusätzliche Termine durch Vereinbarung der Mitglieder (oder Kündigung im Falle höherer Gewalt) zu verabreden. Für mögliche Änderungen folgen Sie bitte einem der sozialen Netzwerke.",
      scheduleBullet1: "NoGi: Kein Kimono (Gi), alle Gruppen",
      scheduleBullet2: "Fortgeschrittene Gruppe: Blue Belts und Upper, Anfänger sind willkommen",
      scheduleBullet3: "Anfängergruppe: Anfängertechniken, kein Sparring",
      scheduleBullet4: "Open Mat: Sparring-Training, alle Gruppen und Gäste sind willkommen, Gi / NoGi, MMA",
      restday: "Ruhetag",
      strahoninec: "Strahoninec",
      murskosredisce: "Mursko Središće",
      cakovec: "Čakovec",

    }
  };


  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }

  return (

    <div>
      <section className="wrapper stylesch">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col3">
              <div className="image round2 fit">
                <img src="images\lined_jj.jpg" alt="" />
              </div>
            </div>
            <div className="col col4">
              <h3>{content.scheduleTopic}</h3>
              <br />
              <p className="joinUsMiddleText">{content.scheduleText}</p>
              <br />

              <ul>
                <li className="joinUsMiddleText"><b>{content.scheduleBullet1}</b></li>
                <li className="joinUsMiddleText"><b>{content.scheduleBullet2}</b></li>
                <li className="joinUsMiddleText"><b>{content.scheduleBullet3}</b></li>
                <li className="joinUsMiddleText"><b>{content.scheduleBullet4}</b></li>

                <br />
              </ul>
              <br />
            </div>
          </div>
        </div>
      </section>

      <table className="styled-table">
        <tbody>
          <tr className="active-row">
            <th scope="row">{content.monday}</th>
            <td colSpan="2">19:00h (NoGi) {content.cakovec}</td>
          </tr>
          <tr>
            <th className="bb" scope="row">{content.tuesday}</th>
            <td colSpan="2">{content.restday}</td>
          </tr>
          <tr className="active-row">
            <th scope="row">{content.wednesday}</th>
            <td colSpan="2">19:00h (Gi) {content.cakovec}</td>
          </tr>
          <tr>
            <th className="bb" scope="row">{content.thursday}</th>
            <td colSpan="2">{content.restday}</td>
          </tr>
          <tr className="active-row">
            <th scope="row">{content.friday}</th>
            <td colSpan="2">19:00h (Gi) {content.cakovec}</td>
          </tr>
          <tr>
            <th className="bb" scope="row">{content.saturday}</th>
            <td colSpan="2">15:00h (Open Mat - Gi&NoGi) {content.cakovec}</td>
          </tr>
          <tr className="active-row">
            <th scope="row">{content.sunday}</th>
            <td colSpan="2">{content.restday}</td>
          </tr>
          <tr></tr>
        </tbody>
      </table>

      <Footer />
    </div>
  );
}
