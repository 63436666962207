import React from 'react';
import '../../App.css';
import './animate.css';
import './JoinUs.css';

function JoinUsTop() {
  let content = {
    Croatian: {
      join: "Pridruži se!",
      accepting: "Upisujemo ",
      new: "Nove",
      members: "članove",
      firstTraining: "Prvi trening besplatan!",
      texttopic: "MS Jiu Jitsu klub konstantno upisuje nove članove",
      mainText: "MS Jiu Jitsu klub prima nove članove od 13 do 113 godina starosti, spol nije bitan kao ni tjelesna sprema - treneri se kao i ostali članovi kluba uvijek prilagode svojim trening partnerima. Ukoliko ste nam se odlučili pridružiti, najbolje je da nas posjetite za vrijeme treninga i upoznate se s ovom borilačkom vještinom iz prve ruke u ugodnoj atmosferi i društvu (sudjelovanje nije obavezno) ili jednostavno dođite na svoj prvi trening. Raspored možete provjeriti ovdje. Želite li više informacija ili se pak želite najaviti da dolazite na trening to možete učiniti putem:",
      li1: "Facebook stranice: https://www.facebook.com/MsBrazilianJiuJitsu",
      li2: "Elektroničkom poštom na bjjk.ms@gmail.com",
      li3: "Instagram Direct Message-om: https://www.instagram.com/ms.jiujitsu/",
      locationText1: "Svi treninzi se održavaju u Čakovcu",
      locationText2: "Ul. Otokara Keršovanija 1, 40000 Čakovec",
      locationText3: "(podrum)",

    },
    English: {
      join: "Join!",
      accepting: "We are enrolling",
      new: "New",
      members: "members",
      firstTraining: "First training free!",
      texttopic: "MS Jiu Jitsu Club is constantly enrolling new members",
      mainText: "MS Jiu Jitsu Club accepts new members from 13 to 113 years of age, gender or physique is not important , coaches just like other club members always adapt to their training partners. If you decide to join us, it is best to visit us during training and get to know this martial art first hand in a pleasant atmosphere and company (participation is not required.) Or simply come to your first training session. You can check the schedule here. Want more information or want to announce that you are coming to training you can do it over:",
      li1: "Facebook page: https://www.facebook.com/MsBrazilianJiuJitsu",
      li2: "By e-mail to bjjk.ms@gmail.com",
      li3: "Instagram Direct Message: https://www.instagram.com/ms.jiujitsu/",
      locationText1: "All trainings are held in Čakovec",
      locationText2: "Ul. Otokara Keršovanija 1, 40000 Čakovec",     
      locationText3: "(basement)",     

    },
    German: {
      join: "Tritt bei!!",
      accepting: "Wir nehmen",
      new: "Neue",
      members: "Mitglieder auf",
      firstTraining: "Erstes Training kostenlos!",
      texttopic: "Der MS Jiu Jitsu Klub schreibt ständig neue Mitglieder ein",
      mainText: "Der MS Jiu Jitsu Klub akzeptiert neue Mitglieder im Alter von 13 bis 113 Jahren. Geschlecht oder Körperbau sind nicht wichtig - Trainer wie andere klubmitglieder passen sich immer an ihre Trainingspartner an. Wenn Sie sich für einen Beitritt entscheiden, ist es am besten, uns während des Trainings zu besuchen unddiese Kampfkunst aus erster Hand in einer angenehmen Atmosphäre und Gesellschaft kennenzulernen (Teilnahme ist nicht obligatorisch). Sie können einfach auch zu Ihrem ersten Training kommen. Sie können den Zeitplan hier überprüfen. Möchten Sie weitere Informationen oder nur bekannt geben, ob Sie zum Training kommen, stehen Ihnen folgende Kanäle zur Verfügung: ",
      li1: "Facebook-Seite: https://www.facebook.com/MsBrazilianJiuJitsu",
      li2: "Per E-Mail an bjjk.ms@gmail.com",
      li3: "Instagram-Direktnachricht: https://www.instagram.com/ms.jiujitsu/",
      locationText1: "Alle Schulungen finden in Čakovec statt",
      locationText2: "Ul. Otokara Keršovanija 1, 40000 Čakovec",     
      locationText3: "(Keller)",     

    }  };

    let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language
    
    switch(languageStoredInLocalStorage) {
      case 'English':
        content = content.English;
        break;
      case 'German':
          content = content.German;
          break;
      default:
        content = content.Croatian;
    }
  return (

    <div>
      <section className="wrapper style2">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col2">
            <div className="header-banner">
              <div className="banner-content">
                <div className="fade-in"><h4 className="sub-title">{content.join}</h4></div>
                <h1 className="banner-title"> {content.accepting} <span className="fade-in">{content.new}</span> {content.members}</h1>
                <h6 className="sub-title fade-in">{content.firstTraining}</h6>
              </div>
            </div>
            </div>
            <div className="col col1 first">
              <div className="image square fit">
                <img src="images\ms_crew_joinus_2.jpg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="wrapper style1">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col3">
              <div className="image round fit">
                <img src="images\gis_pile.jpg" alt="" />
              </div>
            </div>
            <div className="col col4">
              <h3 className="joinUsH3">{content.texttopic}</h3>             
               <br />
              <p className="joinUsMiddleText">{content.mainText}</p>
              <br />
              <ul>
                <li className="joinUsMiddleText"><a className="joinlinks" href="https://www.facebook.com/MsBrazilianJiuJitsu"><b>{content.li1}</b></a></li>
                <li className="joinUsMiddleText"><a className="joinlinks" href="mailto:bjjk.ms@gmail.com"><b>{content.li2}</b></a></li>
                <li className="joinUsMiddleText"><a className="joinlinks" href="https://www.instagram.com/ms.jiujitsu/"><b>{content.li3}</b></a></li>
                <br />
              </ul>            
            </div>
          </div>
        </div>
      </section>

      <section className="wrapper style2">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col2">
            <div className="header-banner">
              <div className="banner-content">
                <div className="fade-in"> <a  className="joinlinks"  href="https://maps.app.goo.gl/r5p6vtdJeXQGqo419" target="_blank" rel="noopener noreferrer"><h4 className="sub-title2">{content.locationText1}</h4><h4 className="sub-title2">{content.locationText2} <br/>{content.locationText3}</h4></a></div>
              </div>
            </div>
            </div>
            <div className="col col1 first">
              <div className="image square2 fit2">
                <a href="https://maps.app.goo.gl/r5p6vtdJeXQGqo419" target="_blank" rel="noopener noreferrer"> <img src="images\gmap_small_msjjk.jpg" alt="" /></a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
}

export default JoinUsTop;
