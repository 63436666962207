import React from "react";
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';



function HeroSection() {
  let content = {
    Croatian: {
      title: "Izgubi kile, Postani fit, Nauči samoobranu...",
      buttontext: "Pogledaj nas u akciji"
    },
    English: {
      title: "Loose weight, Get fit, Learn self defense...",
      buttontext: "Watch us in action"
    },
    German: {
      title: "Gewicht abnehmen, Fit werden, Selbstverteidigung lernen...",
      buttontext: "Schauen Sie uns in Aktion an"
    }  };

    let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language
    
    switch(languageStoredInLocalStorage) {
      case 'English':
        content = content.English;
        break;
      case 'German':
          content = content.German;
          break;
      default:
        content = content.Croatian;
    }
  

  return (
    <div className='hero-container'>
      <div id="cf">
        <img className="bottom" src="/images/C1_exp_darkmares_2019-11-16_17-46-37_v1_EXP_2048.jpg" alt="first one" />
        <img className="top" src="/images/C1_exp_darkmares_2019-11-16_17-47-08_v1_EXP_2048.jpg" alt="second one" />
      </div>       
      <h1 className="droptextshad">MS Jiu Jitsu</h1>
      <p  className="graybackg">    {content.title}   </p>
      <div className='hero-btns'>
        <Button
          className='navbtns'
          buttonStyle='navbtn--primary'
          buttonSize='navbtn--large'
        >
          {content.buttontext}<i className='far fa-play-circle' />
        </Button>
      </div>     
            
    </div>
  );
}

export default HeroSection;
