import React, { useState } from "react";
import './Button.css';
import Modal from "react-modal";
import "./modalStyle.css";
import ReactPlayer from "react-player"


const STYLES = ['btn--primary', 'btn--outline', 'btn--test'];

const SIZES = ['btn--medium', 'btn--large'];

export const Button = ({
  children,
  type,
  onClick,
  buttonStyle,
  buttonSize
}) => {
  const checkButtonStyle = STYLES.includes(buttonStyle)
    ? buttonStyle
    : STYLES[0];

  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(!isOpen);
  }

  const checkButtonSize = SIZES.includes(buttonSize) ? buttonSize : SIZES[0];
  return (<div>
    <button
      className={`btn ${checkButtonStyle} ${checkButtonSize}`}
      onClick={toggleModal}
      type={type}
    >
      {children}
    </button>

    <Modal
      isOpen={isOpen}
      onRequestClose={toggleModal}
      className="mymodal"
      overlayClassName="myoverlay"
    >
      <ReactPlayer
        url="videos/whoweare_msjiujitsu.mp4"
				playing={true}
        controls={true}
        className='react-player'
      />
      <div style={{ float: 'right', marginTop: 10 }}>
        <i onClick={toggleModal} class="fa fa-window-close" aria-hidden="true" style={{ fontSize: "28px", color: "Red" }} ></i>   </div>

    </Modal>
  </div>
  );
};
