import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import { photos } from "./GalleryPhotos";
import Modal from "react-modal";
import "./GalleryModalStyle.css";


function GalleryContainer() {
  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  function closeModal(sorc) {
    setIsOpen(!isOpen);
  }

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setIsOpen(true);
  }, []);

  const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%) scale(0.75)',
      background            : "rgba(0, 0, 0, 0.75)",
      color                 : "white",
      
    }
  };

  return (
    <div>
      <Gallery photos={photos} onClick={openLightbox}/>

      <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
     className="mymodal"
     overlayClassName="myoverlay"
    style={customStyles}

          >
       
        <img src={photos[currentImage].src} alt={photos[currentImage].text}  onClick={closeModal}></img>

    </Modal>


    </div>

    
  );
}
export default GalleryContainer;
