import React, { useEffect } from 'react';
import '../../App.css';
import './Competitions.css';
import Footer from '../Footer';


export default function Competitions() {
	// used to scroll smoothly on page load
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	})

	let content = {
		Croatian: {
			rules: "Pravila",
			comps: "Natjecanja",
			title: "Natjecanja",
			subTitle: "Članovi MS Jiu Jitsu kluba su redovni polaznici natjecanja u regiji, a i šire.",
			mainText: "U regiji se održavaju brojna natjecanja pod pravilima IBJJF-a i UAEJJF-a. MS Jiu Jitsu klub održava tradiciju pohađanja brojnih događaja, bilo unutar Republike Hrvatske ili izvan nje. Isto tako potičemo i podržavamo naše članove da se prijave na čim više natjecanja kao najviši oblik testiranja naučenih vještina. Svako pohađanje natjecanja organiziramo u vlastitom aranžmanu.",

			ibjjfText: "Međunarodna brazilska Jiu-Jitsu federacija (IBJJF) domaćin je nekoliko najvećih svjetskih turnira u brazilskom jiu-jitsuu (BJJ), uključujući Svjetsko prvenstvo u jiu-jitsuu, svjetsko prvenstvo u ne-gi, Pan Jiu-Jitsu prvenstvo i European Open Jiu-Jitsu prvenstvo. Federaciju je oformio Carlos Gracie mlađi, koji je voditelj jedne od najvećih brazilskih udruga jiu-jitsu, Gracie Barra. ",

			rulesText: "Pravila po kojima se održavaju natjecanja provjerite ovdje:",

			uaejjfText: "UAE Jiu-Jitsu federacija osnovana je u studenom 2012. godine kao službeno tijelo za Jiu-Jitsu u UAE. Federacija igra vodeću ulogu u pružanju potpore Jiu-Jitsuu i doprinosi izgradnji jake generacije sportaša iz UAE koji će se istaknuti na lokalnoj i međunarodnoj razini. Uloga Federacije je provoditi politike koje razvijaju sport u suradnji s različitim vladinim tijelima, obrazovnim institucijama i drugim partnerima kako bi se sport unaprijedio lokalno, regionalno i globalno.",
			currentCompsText:"Provjerite raspored nadolazećih natjecanja u regiji na sljedećem linku: ",
		},
		English: {
			rules: "Rules",
			comps: "Competitions",
			title: "Competitions",
			subTitle: "Members of the MS Jiu Jitsu Club are regular participants in competitions in the region and beyond.",
			mainText: "Numerous competitions are held in the region under the rules of the IBJJF and UAEJJF. MS Jiu Jitsu Club maintains the tradition of attending as many events as possible, either inside the Republic of Croatia or abroad. We also encourage and support our members to apply for as many competitions as possible as the highest form of testing of learned skills. We organize each attendance of the competition in our own arrangement.",

			ibjjfText: "The International Brazilian Jiu-Jitsu Federation (IBJJF) hosts several major world tournaments in Brazilian jiu-jitsu (BJJ), including the World Jiu-Jitsu Championship, the World Non-Jiu Championship, the Pan Jiu-Jitsu Championship and the European Open Jiu-Jitsu priority. The federation was created by Carlos Gracie Jr., who is the leader of one of Brazil’s largest jiu-jitsu associations, Gracie Barra.",

			rulesText: "Check the rules according to which competitions are held here:",

			uaejjfText: "The UAE Jiu-Jitsu Federation was established in November 2012 as the official authority for Jiu-Jitsu in the UAE. The Federation plays a leading role in supporting Jiu-Jitsu and contributing to building a strong generation of UAE athletes to excel at the local and international levels. The Federation’s role is to implement policies that develop the sport in collaboration with various government entities, educational institutions and other partners in order to advance the sport locally, regionally and globally.",

			currentCompsText:"Check the schedule of upcoming competitions in the region at the following link: ",

		},
		German: {
			rules: "Regeln",
			comps: "Wettbewerbe",
			title: "Wettbewerbe",
			subTitle: "Mitglieder des MS Jiu Jitsu Klubs nehmen regelmäßig an Wettbewerben in der Region und darüber hinaus teil. ",
			mainText: "In der Region finden nach den Regeln der IBJJF und der UAEJJF zahlreiche Wettbewerbe statt. Der MS Jiu Jitsu Klub pflegt die Tradition, so viele Veranstaltungen wie möglich innerhalb der Republik Kroatien oder im Ausland zu besuchen. Wir ermutigen und unterstützen unsere Mitglieder auch, sich für so viele Wettbewerbe wie möglich zu bewerben, um die erlernten Fähigkeiten auf höchstem Niveau zu testen. Wir organisieren jede Teilnahme am Wettbewerb nach eigenem Arrangement",

			ibjjfText: "Die Internationale Brasilianische Jiu-Jitsu-Föderation (IBJJF) veranstaltet mehrere große Weltturniere im brasilianischen Jiu-Jitsu (BJJ), darunter die Jiu-Jitsu-Weltmeisterschaft, die Nicht-Jiu-Weltmeisterschaft, die Pan Jiu-Jitsu-Meisterschaft und die European Open Jiu-Jitsu-Meisterschaft. Der Verband wurde von Carlos Gracie Jr. gegründet, der der Leiter eines der größten Jiu-Jitsu-Verbände Brasiliens, Gracie Barra, ist.",

			rulesText: "Überprüfen Sie die Regeln, nach denen die Wettbewerbe stattfinden: ",

			uaejjfText: "Die Jiu-Jitsu-Föderation der VAE wurde im November 2012 als offizielle Behörde für Jiu-Jitsu in den VAE gegründet. Die Föderation spielt eine führende Rolle bei der Unterstützung vom Jiu-Jitsu und trägt dazu bei, eine starke Generation von Athleten aus den VAE aufzubauen, die auf lokaler und internationaler Ebene herausragende Leistungen erbringen. Die Föderation hat die Aufgabe, Strategien zur Entwicklung des Sports in Zusammenarbeit mit verschiedenen Regierungsstellen, Bildungseinrichtungen und anderen Partnern umzusetzen, um den Sport lokal, regional und global voranzutreiben.",

			currentCompsText:"Den Zeitplan für bevorstehende Wettbewerbe in der Region finden Sie unter folgendem Link: ",
			}
	};

	let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

	switch (languageStoredInLocalStorage) {
		case 'English':
			content = content.English;
			break;
		case 'German':
			content = content.German;
			break;
		default:
			content = content.Croatian;
	}

	return (
		<div className="wrapper style1 topreg">

			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h2 className="msabouttitle">{content.title}</h2>
						<h3>{content.subTitle}</h3>
					</header>
					<div>
					<p className="msmaintext justifyptext">{content.mainText}</p>
				</div>
				</div>
			</section>
			<section className="wrapper style1">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col3">
              <div className="image round3 fit">
                <img src="images\International_Brazilian_Jiu-Jitsu_Federation_logo2.jpg" alt="" />
              </div>
            </div>
            <div className="col col4">
              <h3 className="joinUsH3">IBJJF</h3>             
               <br />
              <p className="joinUsMiddleText">{content.ibjjfText}</p>
              <br /><p className="joinUsMiddleText">{content.rulesText}<a href="https://ibjjf.com/books-videos" rel="noopener noreferrer" target="_blank" className="button3">{content.rules}</a></p>
              <br />
            </div>
          </div>
        </div>
      </section>
			<section className="wrapper style1">
        <div className="inner">
          <div className="flex flex-2">
            <div className="col col3">
              <div className="image round3 fit">
                <img src="images\uaejjf-800x445.jpg" alt="" />
              </div>
            </div>
            <div className="col col4">
              <h3 className="joinUsH3">UAEJJF</h3>             
               <br />
              <p className="joinUsMiddleText">{content.uaejjfText}</p>
              <br /><p className="joinUsMiddleText">{content.rulesText}<a href="https://events.uaejjf.org/en/regulations/jiu-jitsu-competition-rules" rel="noopener noreferrer" target="_blank" className="button3">{content.rules}</a></p>
              <br />
            </div>
          </div>
        </div>
      </section>
			<section className="wrapper style1">
				<div className="inner">
					<div>
					<p className="msmaintext">{content.currentCompsText} <a href="https://smoothcomp.com/en/events/upcoming" rel="noopener noreferrer" target="_blank" className="button3">{content.comps}</a></p>
				</div>
				</div>
			</section>


			<hr className="major" />


			<Footer />

		</div>

	);
}
