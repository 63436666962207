import React from 'react';
import './Cards.css';
import './SocialMediaCards.css';

function SocialMediaCards(props) {

  let content = {
    Croatian: {
      main_title: "Prisutni smo i na društvenim mrežama!",
      sub_title: "Za najnovije informacije prati jedan od naših kanala društvenih mreža. Informacije o nadolazećim događanjima, natjecanjima ili zadnje informacije iz kluba.",
      card1_title: "Facebook",
      card1_text: "Najnovije informacije o treninzima i aktualnim događanjima",
      card2_title: "Instagram",
      card2_text: "Fotografije s treninga, raznih događanja...",
      card3_title: "YouTube",
      card3_text: "Povremeni video uradci s natjecanja i više...",
    },
    English: {
      main_title: "We are also present on social networks!",
      sub_title: "For the latest information, follow one of our social media channels. Information about upcoming events, competitions or the latest information from the club.",
      card1_title: "Facebook",
      card1_text: "Latest information on trainings and current events",
      card2_title: "Instagram",
      card2_text: "Photos from trainings, various events ...",
      card3_title: "YouTube",
      card3_text: "Occasional videos from the competition and more...",
    },
    German: {
      main_title: "Wir sind auch in sozialen Netzwerken präsent!",
      sub_title: "Um die neuesten Informationen zu erhalten, folgen Sie einem unserer Sozialnetzwerkkanäle. Informationen zu bevorstehenden Veranstaltungen, Wettbewerben oder den neuesten Informationen des Klubs.",
      card1_title: "Facebook",
      card1_text: "Neueste Informationen zu Trainings und aktuellen Ereignissen",
      card2_title: "Instagram",
      card2_text: "Fotos vom Training und verschiedenen Veranstaltungen ...",
      card3_title: "YouTube",
      card3_text: "Gelegentliche Videos vom Wettbewerb und mehr ...",
    }
  };

  let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

  switch (languageStoredInLocalStorage) {
    case 'English':
      content = content.English;
      break;
    case 'German':
      content = content.German;
      break;
    default:
      content = content.Croatian;
  }

  return (
    <div className='cards'>
      <h1>{content.main_title}</h1>
      <h1 className="smsubtitle">{content.sub_title}</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <li className='cards__item'>
              <a className='cards__item__link' href='https:///www.facebook.com/MsBrazilianJiuJitsu/' rel="noopener noreferrer" target='_blank'>
                <figure className='cards__item__pic-wrap' data-category={content.card1_title}>
                  <img
                    className='cards__item__img'
                    alt='carditem'
                    src='images/Facebook_icon.jpg'
                  />
                </figure>
                <div className='cards__item__info'>
                  <h5 className='cards__item__text'>{content.card1_text}</h5>
                </div>
              </a>
            </li>
            <li className='cards__item'>
              <a className='cards__item__link' href='https://www.instagram.com/ms.jiujitsu/' rel="noopener noreferrer" target='_blank'>
                <figure className='cards__item__pic-wrap' data-category={content.card2_title}>
                  <img
                    className='cards__item__img'
                    alt='carditem'
                    src='images/instagram-logo.jpg'
                  />
                </figure>
                <div className='cards__item__info'>
                  <h5 className='cards__item__text'>{content.card2_text}</h5>
                </div>
              </a>
            </li>
            <li className='cards__item'>
              <a className='cards__item__link' href='https:///www.youtube.com/channel/UC2EFYaOspGbr7WBakX8yT3g/' rel="noopener noreferrer" target='_blank'>
                <figure className='cards__item__pic-wrap' data-category={content.card3_title}>
                  <img
                    className='cards__item__img'
                    alt='carditem'
                    src='images/Logo_of_YouTube.jpg'
                  />
                </figure>
                <div className='cards__item__info'>
                  <h5 className='cards__item__text'>{content.card3_text}</h5>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default SocialMediaCards;
