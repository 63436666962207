import React, { useEffect } from 'react';
import '../../App.css';
import './AboutUs.css';
import './WhatIsIt.css';
import Footer from '../Footer';
import ReactPlayer from "react-player"


export default function WhatIsIt() {
	// used to scroll smoothly on page load
	useEffect(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	})

	let content = {
		Croatian: {
			title: "Što je Brazilian Jiu Jitsu?",
			titleSub: "Borilačka vještina, ali i puno više",
			textPart1: "Brazilian Jiu Jitsu je borilačka vještina i kontaktni sport usredotočen na borbu na tlu s ciljem dobivanja dominantne pozicije nad protivnikom koristeći poluge i gušenja. Nastao je kao vještina kod koje manja osoba može svladati većeg protivnika korištenjem ravnoteže i tehnike. Brazilian Jiu Jitsu je postao najbolja bazna borilačka vještina kod MMA-a (mješovitih borilačkih vještina - UFC, Bellator, FFC etc.) Budući da većina samoobrambenih borbi završava na tlu, njih čak 95%, Brazilian Jiu Jitsu postaje esencijalan i za samoobranu.",
			textPart2: "Ovu borilačku vještinu razvila su oko 1920. godine braće Gracie, a najveći uspon popularnosti dobila je prilikom prvih UFC evenata devedesetih u kojima nije bilo konvecionalnih pravila niti težinskih kategorija, a jedini je cilj bio turnirom pokazati koja je borilačka vještina najviše dominantna. Ova promocija pokazala je dominatnost jiu jitsua u kojemu je Royce Gracie osvojio prvi, drugi te četvrti event UFC-a koristeći samo tehnike brazilskog jiu jitsua. I dan danas jiu jitsu pokazuje svoju dominantnost u MMA-u i jednostavno je nezaobilazan dio bilo kojeg borca.",
			textPart3: "Brazilski jiu jitsu jedna je od najsigurnijih kontaktnih borilačkih vještina i ima manju incidenciju ozljeda od popularnih ne-borilačkih sportova. Raspon godina polaznika treninga je zaista širok - ovo je jedna od rijetkih borilačkih vještina koju čak započinju ljudi u kasnijoj životnoj dobi iz očitih razloga: dobivanja kondicije, snage i učenja samoobrane.",


		},
		English: {
			title: "What is Brazilian Jiu Jitsu?",
			titleSub: "Martial art, but also much more",
			textPart1: "Brazilian Jiu Jitsu is a martial art and contact sport focused on ground fighting with the goal of gaining a dominant position on the opponent using levers and chokes. It originated as a skill where a smaller person can overcome a larger opponent using balance and technique. Brazilian Jiu Jitsu has become the best basic martial art in MMA (mixed martial arts; UFC, Bellator, FFC ... etc.) Since most self-defense fights end up on the ground, as much as 95%, Brazilian Jiu Jitsu becomes essential for self-defense as well. ",
			textPart2: "This martial art was developed around 1920 by the Gracie brothers. And the biggest rise in popularity came during the first UFC events of the 1990s, where there were no conventional rules or weight categories, and the only goal of the tournament was to show which martial art is the most dominant. This promotion showed the dominance of Jiu Jitsu in which Royce Gracie won the first, second and fourth UFC event using only the techniques of Brazilian Jiu Jitsu. Even today, Jiu Jitsu shows his dominance in MMA and is simply an unavoidable part of any fighter.",
			textPart3: "Brazilian Jiu Jitsu is one of the safest contact martial arts and has a lower incidence of injury than many or even most popular non-martial art sports. The range of years of training participants is really wide, this is one of the few martial arts that is even started by people in later life for obvious reasons; gaining fitness, strength and learning self-defense.", 
		},
		German: {
			title: "Was ist brasilianisches Jiu Jitsu? ",
			titleSub: "Kampfkunst, aber auch viel mehr ",
			textPart1: "Brasilianisches Jiu Jitsu ist ein Kampf- und Kontaktsport, der sich auf Bodenkämpfe konzentriert und zwar mit dem Ziel, mit Heben und Drosseln eine beherrschende Stellung gegenüber dem Gegner einzunehmen. Es entstand als eine Fähigkeit, bei der eine kleinere Person einen größeren Gegner mit Gleichgewicht und Technik überwinden kann. Das brasilianische Jiu Jitsu ist die beste grundlegende Kampfkunst in MMA (gemischte Kampfkünste - UFC, Bellator, FFCusw.). Da die meisten Selbstverteidigungskämpfe, sogar 95%, auf dem Boden landen, wird das brasilianische Jiu Jitsu auch für die Selbstverteidigung unerlässlich. ",
			textPart2: "Diese Kampfkunst wurde um 1920 von den Gracie-Brüdern entwickelt. Der größte Anstieg der Popularität war bei den ersten UFC-Veranstaltungen der neunziger Jahre zu verzeichnen, bei denen es keine herkömmlichen Regeln oder Gewichtsklassen gab. Das einzige Ziel des Turniers bestand darin, zu zeigen, welche Kampfkunst am dominantesten ist. Diese Promotion zeigte die Dominanz von Jiu Jitsu, wobei Royce Gracie das erste, zweite und vierte UFC-Event nur mit den Techniken des brasilianischen Jiu Jitsu gewann. Noch heute zeigt Jiu Jitsu seine Dominanz in der MMA und ist einfach ein unvermeidlicher Bestandteil eines jeden Kämpfers. ",
			textPart3: "Das brasilianische Jiu Jitsu ist eine der sichersten Kontakt-Kampfkünste und hat eine geringere Verletzungshäufigkeit als viele oder sogar die beliebtesten Nicht-Kampfsportarten. Das Spektrum der jahrelangen Trainingsteilnehmer ist wirklich groß. Dies ist eine der wenigen Kampfkünste, die aus offensichtlichen Gründen sogar von Menschen im späteren Lebensalter begonnen werden: Fitness, Kraft und Selbstverteidigung lernen.", 		}
	};

	let languageStoredInLocalStorage = localStorage.getItem("language"); //fetch saved language

	switch (languageStoredInLocalStorage) {
		case 'English':
			content = content.English;
			break;
		case 'German':
			content = content.German;
			break;
		default:
			content = content.Croatian;
	}

	return (
		<div className="wrapper style1 topreg">

			<section className="wrapper style1">
				<div className="inner">
					<header className="align-center">
						<h2 className="msabouttitle">{content.title}</h2>
						<h3>{content.titleSub}</h3>
					</header>
					<div className="flex">
						<p className="msmaintext justifyptext">{content.textPart1}</p>
						<br></br>
						<div className="centervideo">
							<ReactPlayer
								url="videos/MS_Jiu-Jitsu_Chess.mp4"
								playing={true}
								controls={true}
								loop={true}
								muted={true}
								className='react-player'
							/>
						</div>
						<div>
							<br></br>
							<p className="msmaintext justifyptext">{content.textPart2}</p>
						</div>
						<div>
							<br></br>
							<p className="msmaintext justifyptext">{content.textPart3}</p>
						</div>
					</div>
				</div>
			</section>

			<hr className="major" />


			<Footer />

		</div>

	);
}
