export const photos = [

  {
    src: "images/gallery/114.jpg",
    width: 1440,
    height: 1264
  },
  {
    src: "images/gallery/113.jpg",
    width: 1440,
    height: 1264
  },
  {
    src: "images/gallery/112.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/111.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/110.jpg",
    width: 1200,
    height: 1200
  },
  {
    src: "images/gallery/109.jpg",
    width: 1440,
    height: 1211
  },
  {
    src: "images/gallery/108.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/107.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/106.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/105.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/104.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/103.jpg",
    width: 1440,
    height: 1236
  },
  {
    src: "images/gallery/102.jpg",
    width: 1440,
    height: 1415
  },
  {
    src: "images/gallery/101.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/100.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/99.jpg",
    width: 1440,
    height: 1371
  },
  {
    src: "images/gallery/98.jpg",
    width: 1440,
    height: 1715
  },
  {
    src: "images/gallery/97.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/96.jpg",
    width: 1440,
    height: 1369
  },
  {
    src: "images/gallery/95.jpg",
    width: 1370,
    height: 1200
  },
  {
    src: "images/gallery/DMAR_00001.jpg",
    width: 1024,
    height: 732
  },
  {
    src: "images/gallery/DMAR_00002.jpg",
    width: 1024,
    height: 732
  },
  {
    src: "images/gallery/DMAR_00003.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00004.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00005.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00006.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00007.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00008.jpg",
    width: 1024,
    height: 1433
  },
  {
    src: "images/gallery/DMAR_00009.jpg",
    width: 1024,
    height: 819
  },
  {
    src: "images/gallery/DMAR_00010.jpg",
    width: 1024,
    height: 1280
  },
  {
    src: "images/gallery/DMAR_00011.jpg",
    width: 1024,
    height: 732
  },
  {
    src: "images/gallery/DMAR_00012.jpg",
    width: 1024,
    height: 819
  },
  {
    src: "images/gallery/DMAR_00013.jpg",
    width: 1024,
    height: 682
  },
  {
    src: "images/gallery/DMAR_00014.jpg",
    width: 732,
    height: 1024
  },
  {
    src: "images/gallery/DMAR_00015.jpg",
    width: 1024,
    height: 732
  },
  {
    src: "images/gallery/DMAR_00016.jpg",
    width: 1024,
    height: 682
  },
  {
    src: "images/gallery/DMAR_00017.jpg",
    width: 1024,
    height: 682
  },
  {
    src: "images/gallery/DMAR_00018.jpg",
    width: 732,
    height: 1024
  },
  {
    src: "images/gallery/DMAR_00019.jpg",
    width: 732,
    height: 1024
  },

  {
    src: "images/gallery/94.jpg",
    width: 1440,
    height: 1440
  },
  {
    src: "images/gallery/93.jpg",
    width: 1080,
    height: 810
  },
  {
    src: "images/gallery/92.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/91.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/90.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/89.jpg",
    width: 1080,
    height: 1034
  },
  {
    src: "images/gallery/88.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/87.jpg",
    width: 814,
    height: 815
  },
  {
    src: "images/gallery/86.jpg",
    width: 1080,
    height: 934
  },
  {
    src: "images/gallery/85.jpg",
    width: 1080,
    height: 887
  },
  {
    src: "images/gallery/84.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/83.jpg",
    width: 1080,
    height: 1011
  },
  {
    src: "images/gallery/82.jpg",
    width: 1080,
    height: 810
  },
  {
    src: "images/gallery/81.jpg",
    width: 1024,
    height: 768
  },
  {
    src: "images/gallery/80.jpg",
    width: 1080,
    height: 1080
  },

  {
    src: "images/gallery/79.jpg",
    width: 1080,
    height: 861
  },
  {
    src: "images/gallery/78.jpg",
    width: 1080,
    height: 1074
  },
  {
    src: "images/gallery/77.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/77a.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/76.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/75.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/74.jpg",
    width: 1080,
    height: 939
  },
  {
    src: "images/gallery/73.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/72.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/71.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/TT_00001.jpg",
    width: 1024,
    height: 651
  },
  {
    src: "images/gallery/TT_00002.jpg",
    width: 1024,
    height: 745
  },
  {
    src: "images/gallery/TT_00003.jpg",
    width: 1024,
    height: 754
  },
  {
    src: "images/gallery/TT_00004.jpg",
    width: 1024,
    height: 683
  },
  {
    src: "images/gallery/TT_00005.jpg",
    width: 1024,
    height: 683
  },
  {
    src: "images/gallery/TT_00006.jpg",
    width: 1024,
    height: 643
  },
  {
    src: "images/gallery/70.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/69.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/68.jpg",
    width: 1080,
    height: 981
  },
  {
    src: "images/gallery/67.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/66.jpg",
    width: 962,
    height: 682
  },
  {
    src: "images/gallery/65.jpg",
    width: 1080,
    height: 994
  },
  {
    src: "images/gallery/64.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/63.jpg",
    width: 1511,
    height: 2015
  },
  {
    src: "images/gallery/62.jpg",
    width: 1080,
    height: 942
  },
  {
    src: "images/gallery/61.jpg",
    width: 2047,
    height: 1451
  },
  {
    src: "images/gallery/60.jpg",
    width: 1080,
    height: 1080
  },

  {
    src: "images/gallery/59.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/58.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/57.jpg",
    width: 1080,
    height: 916
  },
  {
    src: "images/gallery/56.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/55.jpg",
    width: 960,
    height: 1280
  },
  {
    src: "images/gallery/54.jpg",
    width: 1280,
    height: 960
  },
  {
    src: "images/gallery/53.jpg",
    width: 1134,
    height: 810
  },
  {
    src: "images/gallery/52.jpg",
    width: 1536,
    height: 2048
  },
  {
    src: "images/gallery/51.jpg",
    width: 1080,
    height: 912
  },
  {
    src: "images/gallery/50.jpg",
    width: 2048,
    height: 1536
  },

  {
    src: "images/gallery/49.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/48.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/47.jpg",
    width: 960,
    height: 960
  },
  {
    src: "images/gallery/46.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/45.jpg",
    width: 1080,
    height: 827
  },
  {
    src: "images/gallery/44.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/43.jpg",
    width: 1440,
    height: 1080
  },
  {
    src: "images/gallery/42.jpg",
    width: 1080,
    height: 858
  },
  {
    src: "images/gallery/41.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/40.jpg",
    width: 2048,
    height: 1536
  },

  {
    src: "images/gallery/39.jpg",
    width: 1280,
    height: 720
  },
  {
    src: "images/gallery/38.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/37.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/36.jpg",
    width: 2048,
    height: 1152
  },
  {
    src: "images/gallery/35.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/34.jpg",
    width: 1440,
    height: 1080
  },
  {
    src: "images/gallery/33.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/32.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/31.jpg",
    width: 2048,
    height: 1308
  },
  {
    src: "images/gallery/30.jpg",
    width: 960,
    height: 960
  },

  {
    src: "images/gallery/29.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/28.jpg",
    width: 1080,
    height: 824
  },
  {
    src: "images/gallery/27.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/26.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/25.jpg",
    width: 960,
    height: 960
  },
  {
    src: "images/gallery/24.jpg",
    width: 1080,
    height: 1080
  },
  {
    src: "images/gallery/23.jpg",
    width: 2048,
    height: 1152
  },
  {
    src: "images/gallery/22.jpg",
    width: 1776,
    height: 1004
  },
  {
    src: "images/gallery/21.jpg",
    width: 2048,
    height: 1152
  },
  {
    src: "images/gallery/20.jpg",
    width: 2048,
    height: 1409
  },

  {
    src: "images/gallery/19.jpg",
    width: 2048,
    height: 1304
  },
  {
    src: "images/gallery/18.jpg",
    width: 2048,
    height: 2046
  },
  {
    src: "images/gallery/17.jpg",
    width: 2048,
    height: 1926
  },
  {
    src: "images/gallery/16.jpg",
    width: 2048,
    height: 1496
  },
  {
    src: "images/gallery/15.jpg",
    width: 960,
    height: 636
  },
  {
    src: "images/gallery/14.jpg",
    width: 960,
    height: 620
  },
  {
    src: "images/gallery/13.jpg",
    width: 903,
    height: 960
  },
  {
    src: "images/gallery/12.jpg",
    width: 960,
    height: 540
  },

  {
    src: "images/gallery/11.jpg",
    width: 960,
    height: 720
  },
  {
    src: "images/gallery/10.jpg",
    width: 1632,
    height: 1224
  },
  {
    src: "images/gallery/9.jpg",
    width: 960,
    height: 720
  },
  {
    src: "images/gallery/8.jpg",
    width: 960,
    height: 647
  },
  {
    src: "images/gallery/7.jpg",
    width: 960,
    height: 1365
  },
  {
    src: "images/gallery/6.jpg",
    width: 960,
    height: 647
  },
  {
    src: "images/gallery/5.jpg",
    width: 600,
    height: 900
  },
  {
    src: "images/gallery/4.jpg",
    width: 2048,
    height: 1536
  },
  {
    src: "images/gallery/3.jpg",
    width: 960,
    height: 693
  },
  {
    src: "images/gallery/2.jpg",
    width: 2048,
    height: 1536
  },

];
