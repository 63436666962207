import React, {useEffect} from 'react';
import '../../App.css';
import SocialMediaCards from '../SocialMediaCards';
import Footer from '../Footer';

function SocialMedia() {
  
// used to scroll smoothly on page load

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  })
  return (
    <>
      <SocialMediaCards />
      <Footer />
    </>
  );
}

export default SocialMedia;
