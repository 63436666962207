import React, { useState} from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MSGallery from './components/pages/Gallery';
import JoinUs from './components/pages/JoinUs';
import Schedule from './components/pages/Schedule';
import AboutUs from './components/pages/AboutUs';
import SocialMedia from './components/pages/SocialMedia';
import WhatIsIt from './components/pages/WhatIsIt';
import Competitions from './components/pages/Competitions';
import NotFound from './components/NotFound';

import { Helmet } from 'react-helmet'

const TITLE = 'MS Jiu Jitsu';

function App() {
  let languageStoredInLocalStorage = localStorage.getItem("language");
  let [language, setLanguage] = useState(
    languageStoredInLocalStorage ? languageStoredInLocalStorage : "Croatian"  );
  return (
    <>
      <Helmet>
        <title>{ TITLE }</title>
      </Helmet>
      <Router>
        <Navbar         language={language}
        handleSetLanguage={language => {
          setLanguage(language);
          storeLanguageInLocalStorage(language);
        }}/>
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/gallery' component={MSGallery} />
          <Route path='/about-us' component={AboutUs} />
          <Route path='/join-us' component={JoinUs} />
          <Route path='/schedule' component={Schedule} />
          <Route path='/social-media' component={SocialMedia} />
          <Route path='/what-is-it' component={WhatIsIt} />
          <Route path='/competitions' component={Competitions} />
          <Route path="" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}
function storeLanguageInLocalStorage(language) {
  localStorage.setItem("language", language);
}

export default App;
